<template>
    <investor-component/>
</template>

<script>
    import InvestorComponent from "@/components/investors/InvestorComponent";
    
    export default {
        name: "Investor",
        title: "Inversionista | Private",
        components: {InvestorComponent}
    }
</script>

<style scoped>

</style>