<template>
    <div>
        Inversionista
    </div>
</template>

<script>
    export default {
        name: "InvestorComponent",
        data: () => ({
            user: null,
            investor: null
        }),
        /*computed: {
            getStatusLogin: function () {
                return this.$store.getters.isLogin;
            }
        },*/
        beforeMount() {
            this.user = this.$store.getters.getUser;
        },
        mounted() {
            // console.info('Usuario Storage: ', this.user);
            this.investor = Object.assign({}, JSON.parse(sessionStorage.getItem('investor')));
            // console.log('Inversionista Storage 1: ', this.investor);
            if (this.investor !== null) {
                // console.log('Inversionista Storage 2: ', this.investor);
                let isLogin = this.investor.isLogin;
                if (isLogin) {
                    // let investor_id = this.user.id;
                    let investor_id = this.user.investors[0].id;
                    this.$router.push({
                        name: "InvestorProfile",
                        params: {
                            id: investor_id,
                        }
                    });
                } else {
                    this.$router.push({ name: "InvestorLogin" });
                }
            } else {
                this.$router.push({ name: "InvestorLogin" });
            }
        }
    }
</script>

<style scoped>

</style>